import type { AxiosResponse } from 'axios'
import BaseService from '@/services/base-service'
import type { IShareKeyData } from '~/stores/types/schema'

export default class SchemaService extends BaseService {
  fetchSchema = async (version: string): Promise<any> => {
    const route = useRoute()
    let url = `/api/${version}/schema/?fmt=json`
    if (route.query.s !== undefined) {
      url = url + '&s=' + route.query.s
    }
    const response: AxiosResponse<any> = await this.http.get(url)
    return response?.data
  }

  generateShareUrl = async (version: string): Promise<IShareKeyData> => {
    const response: AxiosResponse<IShareKeyData> = await this.http.get(
      `/api/${version}/schema/generate_share_url/`,
    )
    return response?.data
  }
}
